import { optionsFun } from '@/utils'

export const props = {
  detail: {
    type: Object,
    default: () => {}
  },
  loading: Boolean,
  time: String
}

export const dictData = {
  health: optionsFun({ 1: 'Normal', 0: 'Abnormal' })
}
