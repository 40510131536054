<template>
  <div class="info _flex">
    <el-image
      :src="detail.towerInfo ? detail.towerInfo.imgUrl : ''"
      :preview-src-list="[resolution(detail, 'towerInfo.imgUrl')]"
      fit="cover"
    />

    <div class="info-detail">
      <p data-label="Tower Name">{{ resolution(detail, 'towerInfo.name') }}</p>
      <p data-label="Tower ID">{{ resolution(detail, 'towerInfo.code') }}</p>
      <p data-label="Location">{{ resolution(detail, 'towerInfo.location') }}</p>
      <p class="_flex _flex-wrap _flex-align__center" data-label="Health Status">
        <i
          :class="[
            detail.health == 1 ? 'el-icon-success' : 'el-icon-error',
            '_font-size__heading2'
          ]"
        ></i
        >{{ dictReadData('health', resolution(detail, 'health'), dictData) }}
      </p>
    </div>
  </div>
</template>

<script>
import { dictReadData } from '@/utils'
import { props, dictData } from './props'

export default {
  props,
  data() {
    return {
      dictData,
      dictReadData
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.info {
  .el-image {
    width: 175px;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
  }

  &-detail {
    margin-left: 10px;
    > p {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 15px;

      &::before {
        content: attr(data-label);
        width: 100%;
        display: inline-block;
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 3px;
      }

      > i {
        color: var(--color-info-light-5);
        margin-right: 10px;
      }
    }
  }
}
</style>
